import React, { useEffect } from "react"
// Layout
import LayoutComponent from "../components/LayoutComponent"
// Blocks
import AsideBlock from "../blocks/articles-page/aside.block"
import ArticleBlock from "../blocks/articles-page/articles.block"
// Hooks
import useArticleData from "../hooks/main-page-hook/useArticlesBlock"
import { Seo } from "../components/seo"
export interface ArticlesPageProps {
  path:any;
}

const ArticlesPage: React.SFC<ArticlesPageProps> = (props: { path: any }) => {
  const dataGRAPH = useArticleData()
  useEffect(() => {
    if (props.path !== "/") {
      const HEADER = document.querySelector(".content-header")
      const HEADER_MOBIL = document.querySelector(".header-mobile")
      HEADER?.classList.add("swap-header")
      HEADER_MOBIL?.classList.add("change-backgorund")
    }
  }, [])
  return (
    <>
      <Seo
        title="Super Sonic Design INC | ニュース・コンテンツ"
        url="https://supersonicdesign.com/articles"
        description
        keywords
        image
        author = "Super Sonic Design INC"
      />
      <LayoutComponent>
        <div className="container container-articles">
          <div className="row no-gutters">
            <div className="col-lg-9">
              <div className="container">
                <h3>ニュース・コンテンツ</h3>
                <div className="row">
                  {dataGRAPH.map((card, i) => (
                    <div className="col-lg-6 col-md-6" key={i}>
                      <ArticleBlock dataCard={card} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <AsideBlock />
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  )
}

export default ArticlesPage
