import React from "react"
import { Link } from "gatsby"
import { URL } from "../../config/settings"
export interface ArticleBlockProps {
  dataCard: any
}

const ArticleBlock: React.SFC<ArticleBlockProps> = allData => {
  const { title, slug, author, category, image, date } = allData.dataCard
  let created="";
  created = date.split(" ")

  return (
    <>
      
      <div className="card-article">
        <Link to={slug}>
          <img
            className="card-article__image"
            // src={`${URL}${image}`}
            src={`${URL}${image}`}
            alt="Banner Card"
          />
        </Link>
        <div className="card-article__create-by">
          <div>
          {author === null ? "" : 
            <p>
              <span>By {author.NameAuthor}</span>
            </p>
          }
            
            <p>
              <span>{created[2] + "年" + created[0] + "月" + created[1] + "日"}</span>
            </p>
          </div>
          {category === null ? "" : 
            <Link
            to={`/articles/${category.SlugCat}`}
            className={`btn-categorie btn-categorie--${category.NameCategory}`}
          >
            {category.NameCategory}
          </Link>
          }
          
        </div>
        <Link className="card-article__description" to={slug}>
          <p>{title.substr(0, 45)}</p>
        </Link>
      </div>
    </>
  )
}

export default ArticleBlock
