import { useStaticQuery, graphql } from "gatsby"

const useArticleData = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { fields: PublicationDate, order: DESC }) {
        nodes {
          Title
          PublicationDate(formatString: "MM DD YYYY")
          Content
          Image {
            url
          }
          Slug
          author_of_article {
            NameAuthor
          }
          category {
            NameCategory
            SlugCat
          }
          keywords
        }
      }
    }
  `)
  return data.allStrapiArticle.nodes.map(article => ({
    title: article.Title,
    date: article.PublicationDate,
    content: article.Content,
    image: article.Image[0].url,
    slug: article.Slug,
    author: article.author_of_article,
    category: article.category,
    keywords: article.keywords
  }))
}

export default useArticleData
